export default {
  nameErrors () {
    const errors = []
    if (!this.$v.item.jsonData.fullName.$dirty) return errors
    !this.$v.item.jsonData.fullName.required && errors.push('Nome é obrigatório.')
    return errors
  },
  
  emailErrors () {
    const errors = []
    if (!this.$v.item.email.$dirty) return errors
    !this.$v.item.email.email && errors.push('Email invalido!')
    !this.$v.item.email.required && errors.push('Email é obrigatório!')
    return errors
  },
  
  cpfErrors () {
    const errors = []
    if (!this.$v.item.jsonData.cpf.$dirty) return errors
    !this.$v.item.jsonData.cpf.required && errors.push('CPF é obrigatório.')
    return errors
  },
  
  birthErrors () {
    const errors = []
    if (!this.$v.item.jsonData.birth.$dirty) return errors
    !this.$v.item.jsonData.birth.required && errors.push('Data de nascimento é obrigatório.')
    return errors
  },
  
  celularErrors () {
    const errors = []
    if (!this.$v.numberPhone.$dirty) return errors
    !this.$v.numberPhone.required && errors.push('Celular é obrigatório.')
    !this.$v.numberPhone.minLength && errors.push('Numero invalido.')
    return errors
  },

  // passwordErrors () {
  //   const errors = []
  //   if (!this.$v.item.jsonData.password.$dirty) return errors
  //   !this.$v.item.jsonData.password.required && errors.push('Senha é obritatorio.')
  //   return errors
  // },

  passwordConfirmationErrors () {
    const errors = []
    if (!this.$v.item.jsonData.passwordConfirmation.$dirty) return errors
    // !this.$v.item.jsonData.passwordConfirmation.required && errors.push('Confirmação de senha  é obritatorio.')
    !this.$v.item.jsonData.passwordConfirmation.sameAsPassword && errors.push('As senhas são diferentes..')
    return errors
  }
}