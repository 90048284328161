<template>
  <v-form>
    <!-- <v-card-title class="modal-cadastro-titulo">
      Editar  - {{ item.jsonData.fullName }}
    </v-card-title> -->

    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field v-model="item.jsonData.fullName" label="Nome:" :error-messages="nameErrors" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field v-model="item.email" label="Email:" :error-messages="emailErrors" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field v-model="item.jsonData.cpf" label="CPF:" v-mask="['###.###.###-##']" :error-messages="cpfErrors" />
        </v-col>


        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field v-model="item.jsonData.birth" label="Data de nascimento:"  v-mask="['##/##/####']" :error-messages="birthErrors" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field v-model="numberPhone" label="celular:" v-mask="'(##) #####-####'" :error-messages="celularErrors" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field v-model="item.jsonData.influencer" label="Influenciador:" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            :type="showPassword ? 'text' : 'password'" 
            label="Senha"
            v-model="item.jsonData.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            v-if="permiteAcao({ path: '/cadastros/entidades/usuarios-web' }, 'edit')"
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            :type="showPassword ? 'text' : 'password'" 
            label="Confirmar Senha:"
            v-model="item.jsonData.passwordConfirmation"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :error-messages="passwordConfirmationErrors"
            v-if="permiteAcao({ path: '/cadastros/entidades/usuarios-web' }, 'edit')"
          />

          <!-- {{ $route }} -->
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="modal-cadastro-footer">
      <v-btn :color="variables.colorPrimary" @click="$emit('closeModal')" class="br-btn br-btn-cancelar">Cancelar</v-btn>
      <v-btn type="submit" :color="variables.colorPrimary"  @click.prevent="submit" class="br-btn" :loading="loading" v-if="permiteAcao({ path: '/cadastros/entidades/usuarios-web' }, 'edit')">Salvar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import { formatPhone } from '@/core/service/utils'
import moment from 'moment'
import { filter } from 'lodash'

import validate from './validate'

export default {
  name: 'UsuarioWebDadosPessoais',
  mixins: [validationMixin],
  directives: {
    mask
  },
  
  data: () => ({
    loading: false,
    showPassword: false,
    numberPhone: ''
  }),

  mounted () {
    console.log(this.$route)
    this.numberPhone = this.item.jsonData.dddPhone + this.item.jsonData.phone
  },

  computed: {
    ...mapGetters('usuariosWeb', {
      item: 'item',
      totalItens: 'totalItens',
    }),
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('filter', ['objFilter']),
    ...mapGetters('pagination', ['page']),
    
    ...validate,
    
    variables: () => variables,
  },
  methods: {
    ...mapActions('usuariosWeb', ['getItens', 'editarItem', 'deletarItem', 'clearItens', 'getItem', 'limparItem', 'editSaveItem']),

    submit () {
      const self = this

      if (self.$v.$invalid) {
        self.$v.item.$touch()
        return false 
      }

      this.loading = true
    
      self.usuarioEditSave(this.item)
    },

    formatLastName (name) {
      let val = String(name).split(' ')
      return { name: val[0], lastName: filter(val, (v, k) => k !== 0).join(' ') }
    },

    usuarioEditSave (val) {
      const self = this
      const dados = Object.assign({}, val)
      dados.jsonData.cod = formatPhone(this.numberPhone).replace(/\D/g, '')
      dados.jsonData.dddPhone = this.numberPhone.replace(/\D/g, '').substring(0, 2),
      dados.jsonData.phone = this.numberPhone.replace(/\D/g, '').substring(2, this.numberPhone.replace(/\D/g, '').length)
      dados.jsonData.cpf = dados.jsonData.cpf.replace(/\D/g, '')
      dados.jsonData.email = dados.email,
      dados.jsonData.birth = moment(dados.jsonData.birth, 'DD/MM/YYYY').format('YYYY-MM-DD')
      delete dados.jsonData.passwordConfirmation

      if (dados.jsonData.fullName) {
        const nomeSobrenome = this.formatLastName(dados.jsonData.fullName)
        dados.jsonData.name = nomeSobrenome.name
        dados.jsonData.lastName = nomeSobrenome.lastName
        dados.jsonData.fullName = `${nomeSobrenome.name || ''} ${nomeSobrenome.lastName || ''}`
      }
      
      delete dados.passwordConfirmation

      self.editSaveItem(dados).then(() => {
        self.$emit('closeModal')
        self.loading = false
        this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, ...this.objFilter })
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'success',
          msg: 'Edição realizado com sucesso!'
        })
      }).catch(err => {
        self.loading = false
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'error',
          msg: err.error
        })
      })
    }
  },

  validations () {
    let args = {
      numberPhone: { required },
      item: {
        email: { required, email },
        jsonData: {
          fullName: { required },
          cpf: { required },
          birth: { required },
          // phone: { required, minLength: minLength(14) },
          passwordConfirmation: { sameAsPassword: sameAs('password') }
        }
      },
    }

    return args
  }
}
</script>